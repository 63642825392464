import React, { Component } from "react";
import * as d3 from "d3";
// import {stackedArea} from "./britecharts/stacked-area"

// const GRADIENT = [
//     "#6875F5",
//     "#9061F9",
//     "#E74694"
// ]

//const REQ_URL = "http://localhost:8000/api"
const REQ_URL = "https://bt-api.krss.at/api"

const GRADIENT = [
    "#00ff00",
    "#eeee00",
    "#ff0000",
]

const MAX_CAP = 180

const LOADING_STATE = ` \
            <svg class="load-state stacked-area-load-state" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 711 325" style="animation: pulse 2s cubic-bezier(0.5, 0, 0.5, 1) infinite;"> \
            <defs> \
                <linearGradient id="lgrad" x1="0%" y1="50%" x2="100%" y2="50%"> \
                    <stop offset="0" stop-color="#ffffff" stop-opacity="0.8"></stop> \
                    <stop offset="33.33%" stop-color="#ffffff" stop-opacity="0.8"></stop> \
                    <stop offset="50%" stop-color="#ffffff" stop-opacity="0"></stop> \
                    <stop offset="66.66%" stop-color="#ffffff" stop-opacity="0.8"></stop> \
                    <stop offset="100%" stop-color="#ffffff" stop-opacity="0.8"></stop> \
                </linearGradient> \
            </defs> \
                <path id="chart-bg" class="chart-bg" style="stroke:#C3C6CF;" d="M3.4,216.5h707.3 M3.4,160.5h707.3 M3.3,103.5h707.3 M3.3,48.5h707.6 M0.4,276.6H710H0.4z"></path> 
                <g transform="translate(20 50)"> \
                    <path id="chart-area" strokelinecap="square" d="M0.34233103,0.593688165 L709.977885,0.593688189" transform="translate(.01 227.976)"></path> \
                    <path fill="#C3C6CF" d="M0.528124801,224.014648 L0.528124801,177.734375 L53.3834796,177.734375 C71.5390789,177.734375 86.8277373,168.972754 101.240241,151.662202 C112.578335,138.044258 121.139826,123.110227 136.974507,91.596773 C137.343842,90.8617404 139.300293,86.9654028 139.856735,85.8583549 C155.041692,55.6476711 163.354313,41.0906306 174.319873,27.7179171 C188.951312,9.87459412 204.885845,0.5 223.830634,0.5 C242.123071,0.5 257.291724,8.27027858 270.907992,23.1359289 C281.228683,34.4036118 289.135925,47.1272372 302.542017,72.085092 C303.275893,73.4513345 306.289669,79.0766612 307.063369,80.5168656 C321.41025,107.222876 330.088083,120.97663 341.470704,132.92446 C355.88994,148.05969 371.908861,155.792969 391.654853,155.792969 C412.142049,155.792969 428.763593,152.325614 442.880698,145.765582 C454.197328,140.506893 463.373931,133.679865 473.786035,123.626931 C476.528659,120.978915 486.44777,110.911455 488.791866,108.6483 C502.907223,95.0203436 514.194325,88.9355469 530.135322,88.9355469 C546.532652,88.9355469 559.505909,97.338499 575.973261,115.41103 C579.723508,119.526837 593.103621,135.086814 592.915496,134.871799 C605.09738,148.794859 614.368835,157.635549 625.072091,164.58539 C638.386599,173.230769 652.701021,177.734375 669.279853,177.734375 L673.779853,177.734375 L673.779853,224.014648 L0.528124801,224.014648 Z"></path> \
                </g> \
            </svg> `


class OccChart extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.gradient = GRADIENT;
        this.fetchUrl = props.fetchUrl;
        this.curve = d3.curveStepBefore;
        this.data = [];
    }

    setLoading(ls) {
        if (ls) {
            this.container.html(LOADING_STATE);
        } else {
            this.container.select(".load-state").remove();
        }
    }

    async drawChart() {
        //this.setLoading(true);
        this.container = d3.select(this.myRef.current).attr("id", this.fetchUrl.replace("/", ""));
        let container = this.container;

        // remove loading state
        this.setLoading(false);
        // Rendering the chart again
        container.html("");
        //container.append(AreaChart(container.data()));
        //container.append("b").html("Test");
        if (this.data.length > 1) {
            oc(container, { curve: this.curve });
        } else {
            let msg = "No data";
            if (this.props.type === 'live') {
                msg = "No data for today (yet)"
            }
            container.append("div").attr("class", "center-div").append("span").attr("class", "data-placeholder").html(msg);
        }

        //this.customize();
    };

    async fetchData() {
        let data = {};

        let url = REQ_URL + this.fetchUrl + "?" + new URLSearchParams(this.props.queryParams);
        //console.log(url);


        if (this.props.type === 'live') {
            //console.log(this.fetchUrl);
            let promise = fetch(url)
                .then((r) => (r.json()));

            let resp = await promise;
            //console.log(resp);
            // set maximum occupancy
            //console.log(resp);
            data = resp["data"].map((e) => {
                let ne = {};
                //ne.topicName = "occupancy";
                ne.date = d3.isoParse(e["time"]);
                ne.value = e["count"];
                return ne;
            });

        } else {
            let promise = fetch(url)
                .then((r) => {
                    if (r["status"] === 404) {
                        return { error: true }
                    } else {
                        return r.json()
                    }
                });

            let resp = await promise;
            if (!resp.error) {
                if (resp["is_average"]) {
                    this.curve = d3.curveBasis;
                } else {
                    this.curve = d3.curveStepBefore;
                }

                data = resp["data"].map((e) => {
                    let ne = {};
                    //ne.topicName = "occupancy";
                    ne.date = d3.isoParse(e["time"]);
                    ne.value = e["count"];
                    return ne;
                });
                this.maxocc = Math.max(...data.map((e) => { return e.value }));
            } else {
                data = [];
            }
        }
        var filterOpen = (d) => {
            return ((d.date.getHours() > 7) || (d.date.getHours() === 7 && d.date.getMinutes() >= 30)) && 
                ((d.date.getHours() < 22) || (d.date.getHours() === 22 && d.date.getMinutes() === 0))
        }

        data = data.filter(filterOpen);

        this.data = data;
    }

    async componentDidMount() {
        // replace id with fetch url
        this.container = d3.select(this.myRef.current).attr("id", this.fetchUrl.replace("/", ""));

        this.setLoading(true);

        await this.fetchData();

        this.container.datum(this.data);

        window.addEventListener("resize", () => { this.drawChart() });

        await this.drawChart();
    }

    async componentDidUpdate(prevProps) {
        // only refetch and redraw on property change
        if (this.props.queryParams !== prevProps.queryParams) {
            this.setLoading(true);
            await this.fetchData();
            this.container.datum(this.data);
            await this.drawChart();
        }
    }

    render() {
        return (
            <div ref={this.myRef}>
                <div role="status" className="p-4 max-w-sm rounded border border-gray-200 shadow animate-pulse md:p-6 dark:border-gray-700">
                    <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32 mb-2.5"></div>
                    <div className="mb-10 w-48 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                    <div className="flex items-baseline mt-4 space-x-6">
                        <div className="w-full h-52 bg-gray-200 rounded-t-lg dark:bg-gray-700"></div>
                        <div className="w-full h-36 bg-gray-200 rounded-t-lg dark:bg-gray-700"></div>
                        <div className="w-full h-52 bg-gray-200 rounded-t-lg dark:bg-gray-700"></div>
                        <div className="w-full h-44 bg-gray-200 rounded-t-lg dark:bg-gray-700"></div>
                        <div className="w-full h-60 bg-gray-200 rounded-t-lg dark:bg-gray-700"></div>
                        <div className="w-full h-52 bg-gray-200 rounded-t-lg dark:bg-gray-700"></div>
                        <div className="w-full h-40 bg-gray-200 rounded-t-lg dark:bg-gray-700"></div>
                        <div className="w-full h-44 bg-gray-200 rounded-t-lg dark:bg-gray-700"></div>
                    </div>
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        )
    }
}

function oc(container, {
    curve = d3.curveStepBefore
}) {
    // set the dimensions and margins of the graph
    var w = container.node() ? container.node().getBoundingClientRect().width : false
    var h = Math.min(w * 2 / 3, 400);
    var margin = { top: 10, right: 30, bottom: 30, left: 50 },
        width = w - margin.left - margin.right,
        height = h - margin.top - margin.bottom;

    var data = container.datum();
    // append the svg object to the body of the page
    var svg = container.append("svg")
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
        .append("g")
        .attr("transform",
            "translate(" + margin.left + "," + margin.top + ")");

    //console.log(data);

    // Add X axis (date format)
    var x = d3.scaleTime()
        .domain(d3.extent(data, function (d) { return d.date; }))
        .range([0, width]);
    var xTicks = x.ticks([5]);
    //xTicks.push(timeDom[0]);
    //xTicks.push(timeDom[1]);



    svg.append("g")
        .attr("transform", "translate(0," + height + ")")
        .call(d3.axisBottom(x).tickValues(xTicks).tickFormat((d) => d3.timeFormat("%H:%M")(d)));

    let maxOcc = d3.max(data, (d) => d.value);
    let yMax = Math.max(...[maxOcc, MAX_CAP])
    // Add Y axis
    var y = d3.scaleLinear()
        .domain([0, yMax])
        .range([height, 0]);

    var numYTicks = 5;
    var yTicks = y.ticks([numYTicks]);
    yTicks.push(180);
    //console.log(yTicks);
    var yAxis = d3.axisLeft(y).tickValues(yTicks);
    svg.append("g")
        .call(yAxis);

    let gridAxis = d3.axisLeft(y)
        .tickSize(-width)
        .tickValues(yTicks).tickFormat("");
    // horizontal grid lines
    svg.append("g").call(gridAxis)
        .attr("opacity", "0.3")
        .style("stroke-dasharray", "10,15")
        .attr("id", "gridlines");

    // remove topmost grid line
    container.select("#gridlines").select(".domain").remove();


    // Add the gradient
    // figure out maximum occupancy
    const capRatio = maxOcc / MAX_CAP;
    const gradientOffs = -(1 - capRatio) * 100 + "%";

    let gradientId = `${container.attr("id")}Grad`;

    // edit gradient
    let gradient = svg
        .append("linearGradient")
        .attr("id", gradientId)
        .attr("x1", "0%")
        .attr("x2", "0%")
        .attr("y1", "100%")
        .attr("y2", gradientOffs);

    // add new 'fancy' stops
    let gr = GRADIENT;
    let numStops = gr.length;
    const inc = 100 / (numStops - 1);
    for (var i = 0; i < numStops; i++) {
        gradient
            .append("stop")
            .attr("offset", i * inc + "%")
            .attr("stop-color", gr[i]);
    }

    // Add the area
    svg.append("path")
        .attr("fill", `url(#${gradientId})`)
        .attr("style", `fill-opacity: 0.3`)
        .attr("d", d3.area()
            .curve(curve)
            .x(function (d) { return x(d.date) })
            .y0(y(0))
            .y1(function (d) { return y(d.value) })
        )

    // Add the line
    svg.append("path")
        .attr("stroke", `url(#${gradientId})`)
        .attr("style", `fill: none; stroke-width: 2pt`)
        .attr("d", d3.line()
            .curve(curve)
            .x(function (d) { return x(d.date) })
            .y(function (d) { return y(d.value) })
        )
}

export default OccChart;