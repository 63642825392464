import { Button, Label, Select, Footer, DarkThemeToggle, Flowbite, Navbar } from 'flowbite-react'
import { FunnelIcon, ArrowPathIcon } from '@heroicons/react/24/outline'
import OccupancyChart from "./AreaChart"
import { Component } from "react"

function getStartDate(selval) {
  let dt = new Date();
  if (selval === 'last-90d') {
    dt.setDate(dt.getDate() - 90);
  } else if (selval === 'last-month') {
    dt.setDate(dt.getDate() - 30);
  } else if (selval === 'last-14d') {
    dt.setDate(dt.getDate() - 14);
  } else if (selval === 'last-week') {
    // special case for past 23h
    if (dt.getHours() > 22) {
      dt.setDate(dt.getDate() - 6);
    } else {
      dt.setDate(dt.getDate() - 7);
    }
  }
  let dtArr = [dt.getFullYear(), dt.getMonth() + 1, dt.getDate()];
  return dtArr.join("-");
}

const DATE_DESC = {
  "all": "All Time",
  "last-90d": "Last 90 Days",
  "last-month": "Last Month",
  "last-14d": "Last 14 Days",
  "last-week": "Last Week"
}

const WEATHER_DESC = {
  "all": "All Weather",
  "clear": "Clear",
  "rain": "Rain"
}

const HOLIDAY_DESC = {
  "all": "All Days",
  "holidays-only": "Holidays Only",
  "non-holidays-only": "Non-Holidays Only"
}


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFilter: false,
      queryParams: {
        start_date: getStartDate('last-14d')
      }
    };
    this.lastVals = {
      weather: 'all',
      holiday: 'all',
      time: 'last-14d'
    };
  }

  getFilter() {
    if (this.state.showFilter) {
      let weatherVal = document.getElementById("weather").value;
      let timeVal = document.getElementById("time").value;
      let holidayVal = document.getElementById("holiday").value;
      return {
        weather: weatherVal,
        time: timeVal,
        holiday: holidayVal
      }
    } else {
      return {}
    }
  }

  updateParams() {
    if (this.state.showFilter) {
      let vals = this.getFilter();

      let queryParams = {};

      // map weather selector
      if (vals.weather !== 'all') {
        queryParams.weather = vals.weather;
      }

      // map holiday selector
      if (vals.holiday !== 'all') {
        if (vals.holiday === 'holidays-only') {
          queryParams.holiday = true;
        } else {
          queryParams.holiday = false;
        }
      }

      // map time selector
      if (vals.time !== 'all') {
        queryParams.start_date = getStartDate(vals.time);
      }

      this.lastVals = vals;

      this.setState({
        showFilter: this.state.showFilter,
        queryParams: queryParams
      });

    }
  }

  componentDidUpdate() {
    let fv = this.getFilter();
    console.log(fv, this.lastVals);
    if (fv !== {} && JSON.stringify(fv) !== JSON.stringify(this.lastVals)) {
      this.updateParams();
    }
  }

  matchTheme() {
    let bdy = document.getElementsByTagName("body")[0];
    let col = window.getComputedStyle(bdy).backgroundColor;
    document.querySelector('meta[name="theme-color"]').setAttribute("content", col);
  }

  render() {
    this.matchTheme();
    return (
      <Flowbite>
        <Navbar
          fluid={true}
          rounded={true}
        >
          <Navbar.Brand>
            <div className="text-transparent font-extrabold bg-clip-text bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500">
              <img className="h-6 mr-2 md:h-8" src="./block.svg" alt="blocktracker icon"></img>
            </div>
            <span className="font-extrabold">Blocktracker</span>
          </Navbar.Brand>
          <div className="flex items-center">
            <div className="mr-3 dark:text-gray-400 text-gray-600 visible sm:invisible">
              <Button color="none" onClick={() => window.location.reload()}>
                <ArrowPathIcon className="h-5 w-5 p-0" stroke="currentColor" />
              </Button>
            </div>
            <div onClick={() => this.matchTheme()}>
              <DarkThemeToggle />
            </div>
          </div>
        </Navbar>
        <div className="container mx-auto px-4 py-4">
          <div className="pb-2">
            <h2 className="text-2xl font-extrabold dark:text-white ml-2 mb-4">Current Occupancy</h2>
            <OccupancyChart curve="stepAfter" fetchUrl="/today" type={"live"} />
          </div>
          <div>
            <div className="flex justify-between mx-2">
              <h2 className="text-2xl font-extrabold dark:text-white mb-4">Trends<small className="ml-3 font-semibold text-gray-500 dark:text-gray-400">{DATE_DESC[this.lastVals.time]}</small></h2>
              {!this.state.showFilter && <div id="show-filter">
                <Button size="sm" color="purple" onClick={() => { this.setState({ showFilter: true }) }}>
                  <FunnelIcon className="mr-2 h-5 w-5" />
                  Show Filter
                </Button>
              </div>}
              {this.state.showFilter && <div id="hide-filter">
                <Button size="sm" color="light" onClick={() => this.setState({ showFilter: false })}>
                  <FunnelIcon className="mr-2 h-5 w-5" />
                  Hide Filter
                </Button>
              </div>}
            </div>
            {this.state.showFilter &&
              <div id="filter" className="m-2 px-4 py-3 dark:bg-gray-750 bg-gray-100 rounded-md">
                <p className="font-bold pb-1">Filter Trends</p>
                <div className="grid md:grid-cols-3 grid-cols-1">
                  <div id="selectWeather">
                    <div className="mb-1 ml-0.5">
                      <Label htmlFor="time" value="Time" />
                    </div>
                    <div className="mb-1 md:mr-3">
                      <Select
                        id="time"
                        required={true}
                        onChange={() => this.updateParams()}
                        defaultValue={this.lastVals.time}
                      >
                        {
                          Object.keys(DATE_DESC).map((key) => {
                            return (
                              <option key={key} value={key}>
                                { DATE_DESC[key] }
                              </option>
                            )
                            })
                        }
                      </Select>
                    </div>
                  </div>
                  <div id="selectWeather" className="md:mx-1.5">
                    <div className="mb-1 ml-0.5" >
                      <Label htmlFor="weather" value="Weather" />
                    </div>
                    <div className="mb-1">
                      <Select
                        id="weather"
                        required={true}
                        defaultValue={this.lastVals.weather}
                        onChange={() => this.updateParams()}
                      >
                        {
                          Object.keys(WEATHER_DESC).map((key) => {
                            return (
                              <option key={key} value={key}>
                                { WEATHER_DESC[key] }
                              </option>
                            )
                            })
                        }
                      </Select>
                    </div>
                  </div>
                  <div id="selectHoliday" className="md:ml-3">
                    <div className="mb-1">
                      <Label htmlFor="holiday" value="Holiday" />
                    </div>
                    <div className="mb-1">
                      <Select
                        id="holiday"
                        required={true}
                        onChange={() => this.updateParams()}
                        defaultValue={this.lastVals.holiday}
                      >
                        {
                          Object.keys(HOLIDAY_DESC).map((key) => {
                            return (
                              <option key={key} value={key}>
                                { HOLIDAY_DESC[key] }
                              </option>
                            )
                            }
                          )
                        }
                      </Select>
                    </div>
                  </div>
                </div>
              </div>
            }
            <div className="grid grid-cols-1 md:grid-cols-2">
              <div className="weekday-div">
                <p className="trend-weekday-label">Monday</p>
                <OccupancyChart fetchUrl="/weekday/0" queryParams={this.state.queryParams} />
              </div>
              <div className="weekday-div">
                <p className="trend-weekday-label">Tuesday</p>
                <OccupancyChart fetchUrl="/weekday/1" queryParams={this.state.queryParams} />
              </div>
              <div className="weekday-div">
                <p className="trend-weekday-label">Wednesday</p>
                <OccupancyChart fetchUrl="/weekday/2" queryParams={this.state.queryParams} />
              </div>
              <div className="weekday-div">
                <p className="trend-weekday-label">Thursday</p>
                <OccupancyChart fetchUrl="/weekday/3" queryParams={this.state.queryParams} />
              </div>
              <div className="weekday-div">
                <p className="trend-weekday-label">Friday</p>
                <OccupancyChart fetchUrl="/weekday/4" queryParams={this.state.queryParams} />
              </div>
              <div className="weekday-div">
                <p className="trend-weekday-label">Saturday</p>
                <OccupancyChart fetchUrl="/weekday/5" queryParams={this.state.queryParams} />
              </div>
              <div className="md:col-span-2 weekday-div">
                <p className="trend-weekday-label">Sunday</p>
                <OccupancyChart fetchUrl="/weekday/6" queryParams={this.state.queryParams} />
              </div>
            </div>
          </div>
        </div>
        <Footer container={true} className="fixed bottom-0 p-4">
          <Footer.Copyright
            href="https://krss.at"
            by="Klaus Kraßnitzer"
            year={2022}
          />
          <Footer.LinkGroup title="legal">
            <Footer.Link
              href="https://krss.at/imprint.html"
            >
              Imprint / Privacy Policy
            </Footer.Link>
          </Footer.LinkGroup>
        </Footer>
      </Flowbite>
    );

  }
}

export default App;
